import SvgIcon from '@mui/material/SvgIcon';
import { SxProps } from '@mui/material/styles';

interface ResumeIconProps {
  sx: SxProps;
}

const ResumeIcon = ({ sx }: ResumeIconProps) => (
  <SvgIcon sx={sx}>
    <svg
      version="1.1"
      id="svg14550"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      xmlns-svg="http://www.w3.org/2000/svg"
    >
      <defs id="defs14554" />
      <g id="g14556">
        <path
          fill="#eeebe9"
          d="m 66.238879,510.66513 c -5.337367,-1.9357 -10.293272,-6.5856 -12.833433,-12.041 L 51.019538,493.5 V 256 18.5 l 2.361231,-5.070863 C 56.087064,7.6172312 61.400449,2.7958124 66.922284,1.1414305 69.683957,0.31401316 103.36883,0 189.36602,0 H 308 V 76.5 153 h 76.5 76.5 l -0.01,170.25 -0.01,170.25 -2.36123,5.07086 c -2.70629,5.81191 -8.01968,10.63333 -13.54151,12.28771 C 439.509,512.527 70.853618,512.33876 66.238879,510.66513 Z M 343.83996,457.39246 C 362.31581,448.18556 362.40828,422.16873 344,412.36416 340.64605,410.57779 335.90981,410.5 230.5,410.5 h -110 l -4.14255,2.21551 c -17.801076,9.52032 -17.912484,35.84916 -0.18907,44.68111 L 121.39269,460 H 230 338.60731 Z m 50.71295,-102.06223 c 17.70891,-9.48069 19.36607,-33.68486 2.99907,-43.80379 L 392.65684,308.5 H 256 119.34316 l -4.89514,3.02644 c -18.076073,11.17557 -14.04539,39.22285 6.54551,45.54655 1.37856,0.42337 62.58147,0.69265 136.00647,0.59839 L 390.5,357.5 Z M 255.99421,239.25 c -0.007,-21.47994 -0.94056,-27.20283 -5.51953,-33.85129 -23.7892,-34.54086 -123.52706,-32.78259 -144.29533,2.54377 -2.65112,4.50951 -2.68272,4.78665 -2.99927,26.30752 L 102.86016,256 H 179.43008 256 l -0.006,-16.75 z M 189.20078,151.89492 C 228.10905,143.7811 243.00707,95.167068 215.12876,67.288765 183.02527,35.185272 129,57.27193 129,102.5 c 0,31.76915 29.32816,55.833 60.20078,49.39492 z M 358,51.5 C 358,23.175 358.33915,0 358.75368,0 359.73419,0 461,101.27306 461,102.25364 461,102.66414 437.825,103 409.5,103 H 358 Z"
          id="path2819"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default ResumeIcon;
